(function ($) {
  Drupal.behaviors.gnavOffersFormatterV2 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $formatter = $('.js-gnav-offers-formatter-v2', context);
      var isTickerEnabled = $formatter.find('.js-gnav-offers-formatter__ticker').length > 0;

      if (isTickerEnabled) {
        var $tickerInner = $formatter.find('.js-gnav-offers-formatter__ticker-inner');
        var $originalOffer = $tickerInner.find('.js-gnav-offer-v2').first();
        var tickerSpeed = parseInt($tickerInner.data('ticker-speed')) || 5;
        var baseDuration = 70;
        var duration = baseDuration * (1 / Math.pow(tickerSpeed / 5, 1.2));

        $tickerInner.find('.js-gnav-offer-v2').not($originalOffer).remove();

        const $firstFiveWrapper = $('<div class="offers-group"></div>');

        for (let i = 0; i < 10; i++) {
          $firstFiveWrapper.append($originalOffer.clone());
        }
        $tickerInner.append($firstFiveWrapper);

        const $secondFiveWrapper = $('<div class="offers-group"></div>');

        for (let i = 0; i < 10; i++) {
          $secondFiveWrapper.append($originalOffer.clone());
        }
        $tickerInner.append($secondFiveWrapper);
        $originalOffer.remove();

        $tickerInner.find('.offers-group').css({
          'animation-duration': duration + 's'
        });

        $tickerInner.find('.offers-group:nth-child(2)').css({
          'animation-duration': duration + 's',
          'animation-delay': -(duration / 2) + 's'
        });
      }

      var $carousel = $('.js-gnav-offers-formatter-carousel', $formatter);
      var $slides = $carousel.find('.gnav-offers-formatter__carousel-item');
      var $arrow = $('.js-gnav-offers-formatter-carousel-arrow', $carousel);
      var slideIndex = 1;
      var timer = null;
      var autoplay = $carousel.data('slides-autoplay');
      var speed = parseInt($carousel.data('speed'));

      showSlides(slideIndex);

      if ($.cookie('hide_header_offer_banner')) {
        $('body').addClass('gnav-offers-hidden');

        return;
      }
      $carousel.removeClass('not-initialized');
      // Next/previous controls
      $arrow.once().on('click', function () {
        var newIndex = $(this).data('index');

        clearTimeout(timer);
        showSlides((slideIndex += newIndex));
      });

      function showSlides(n) {
        if (n === undefined) {
          n = ++slideIndex;
        }
        if (n > $slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = $slides.length;
        }
        $slides.removeClass('item-active');
        $slides.eq(slideIndex - 1).addClass('item-active');
        if (autoplay) {
          timer = setTimeout(showSlides, speed);
        }
      }
    }
  };
})(jQuery);
